import { ThemeProvider } from "@mui/material/styles"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { logger } from './utilities'

// contexts
import { FirebaseProvider } from './contexts/firebaseContext'
import { AuthProvider, useAuth } from "./contexts/authContext"

// themes
import darkTheme from './themes/dark'

// views
import HomeView from "./views/homeView/HomeView"
import LoginView from "./views/loginView/LoginView.js"
import LandingView from "./views/landingView/LandingView"
import PricingView from "./views/pricingView/PricingView"
import SupportView from "./views/supportView/SupportView"

logger?.info('App Environment:', process.env.REACT_APP_ENV)

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth()
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated ? (
          <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/authgate', state: { from: props.location } }} />
          )
      }
    />
  )
}

function App() {
  return (
    <>
    <FirebaseProvider>
      <AuthProvider>
        <ThemeProvider theme={darkTheme}>
          <Router>
            <Switch>
              <PrivateRoute
                exact path="/app"
                component={HomeView}
                getUserConfirmation={(message, callback) => {
                  const allowTransition = window.confirm(message)
                  callback(allowTransition)
                }}
              />
              <Route exact path="/authgate" component={LoginView} />
              <Route exact path="/pricing" component={PricingView} />
              <Route exact path="/support" component={SupportView} />
              <Route exact path="/" component={LandingView} />
              <Redirect to="/" />
            </Switch>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </FirebaseProvider>
    </>
  )
}

export default App
