import { useState, useEffect, useMemo, useRef } from 'react'
import useStateRef from 'react-usestateref'
import { Prompt } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
// import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'
import { Howl } from 'howler'
// import ResetIcon from '@mui/icons-material/RestartAlt'
import { useBeforeunload } from 'react-beforeunload'
import useMediaQuery from '@mui/material/useMediaQuery'
import { doc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore'
import { logger, SilentAudio } from '../../utilities'

// assets
import notificationSound from '../../assets/sounds/notification.wav'
import clickSound from '../../assets/sounds/click.wav'
import startOpSound from '../../assets/sounds/startOp.wav'

// components
import Layout from '../../components/Layout'
import Widget from '../../components/Widget'
import Footer from '../../components/Footer'
// import Tooltip from '../../components/LightTooltip'
import AlertDialog from '../../components/AlertDialog'
import InfoSection from './components/InfoSection'

// contexts
import { useFirebase } from '../../contexts/firebaseContext'
import { useAuth } from '../../contexts/authContext'

// add leading zero to numbers upto 2 digits
const pad0 = (num) => String(num).padStart(2, 0)

// react hook to handle the state of form fields
const useField = (initialState) => {
  const [value, setValue] = useState(initialState)
  const onChange = (e) => setValue(e.target.value)
  return { value, onChange }
}

// get remaining time
const getRemainingTime = (endTime) => {
  const now = new Date()
  if (now > endTime) { return ({minutes: 0, seconds: 0}) }
  const diff = Math.ceil((endTime - now) / 1000)
  const minutes = Math.floor(diff / 60)
  const seconds = Math.ceil(diff % 60)
  return ({ minutes, seconds })
}

// get elapsed time by end time and duration
const getElapsedTime = (endTime, durationInMins, rate = 1) => {
  const now = new Date()
  if (now > endTime) {
    return ({
      minutes: Math.floor(durationInMins * rate),
      seconds: rate === 0.5 ? 30 : 0
    })
  }
  const durationInSecs = durationInMins * 60
  const diff = (durationInSecs - Math.ceil((endTime - now) / 1000)) * rate
  const minutes = Math.floor(diff / 60)
  const seconds = Math.floor(diff % 60)
  return ({ minutes, seconds })
}

// add balance
const addBalance = (balance, minutes, seconds) => {
  const newBalance = { ...balance}
  newBalance.minutes += minutes
  newBalance.seconds += seconds
  if (newBalance.seconds >= 60) {
    newBalance.minutes++
    newBalance.seconds-=60
  }
  if(newBalance.minutes >= 60) {
    newBalance.hours++
    newBalance.minutes-=60
  }
  if (newBalance.hours > 999) {
    newBalance.hours = 1000
    newBalance.minutes = 0
    newBalance.seconds = 0
  }
  return newBalance
}

// subtract balance
const subtractBalance = (balance, minutes, seconds) => {
  const newBalance = { ...balance }
  newBalance.minutes -= minutes
  newBalance.seconds -= seconds
  if (newBalance.seconds < 0) {
    newBalance.minutes--
    newBalance.seconds+=60
  }
  if(newBalance.minutes < 0) {
    newBalance.hours--
    newBalance.minutes+=60
  }
  if(newBalance.hours < 0) {
    newBalance.hours = 0
    newBalance.minutes = 0
    newBalance.seconds = 0
  }
  return newBalance
}

// display browser notification
const showNotification = ({ title, body }) => {
  if ('Notification' in window && Notification.permission === 'granted') {
    new Notification(title, {
      body,
      silent: true
    })
  }
}

const Status = {
  INITIALIZING: 'initializing',
  SYNCING: 'syncing',
  DEPOSITING: 'depositing',
  WITHDRAWING: 'withdrawing',
  READY: 'ready',
  ERROR: 'error'
}

const zeroBalance = { hours: 0, minutes: 0, seconds: 0 }
const zeroRemainingTime = { minutes: 0, seconds: 0 }

const silentAudio = new SilentAudio()

function HomeView() {
  const theme = useTheme()
  const { firestore: db } = useFirebase()
  const { user } = useAuth()
  const [balance, setBalance] = useStateRef(zeroBalance)
  const [status, setStatus, statusRef] = useStateRef(Status.INITIALIZING)
  const [confirmReset, setConfirmReset] = useState(false)
  const timers = useRef(null)
  const isPaused = useRef(false)
  const duration = useField(25)
  const depositRate = useField(1)
  const [remainingTime, setRemainingTime] = useState(zeroRemainingTime)
  const notificationHowl = useMemo(() => new Howl({ src: [notificationSound] }), [])
  const clickHowl = useMemo(() => new Howl({ src: [clickSound] }), [])
  const startOpHowl = useMemo(() => new Howl({ src: [startOpSound] }), [])
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const userDocRef = useMemo(() => doc(db, 'users', user.uid), [db, user.uid])

  // runOnce useEffect to set notification permission
  useEffect(() => {
    let shouldRunEffect = true
    const runEffect = async () => {
      if ('Notification' in window) {
        if (Notification.permission === 'default') {
          const permission = await Notification.requestPermission()
          if (shouldRunEffect && permission === 'granted') {
            showNotification({
              title: 'Jack of Time',
              body: 'All set! Now we can notify you when the withdraw or deposit is done.'
            })
          }
        }
      }
    }
    runEffect()
    return () => shouldRunEffect = false
  }, [])

  // runOnce useEffect to initialize app
  useEffect(() => {
    logger?.debug('attaching user doc listener')
    const detachUserDoc = onSnapshot(userDocRef,
      async (doc) => {
        logger?.debug('user doc changed', doc.metadata.hasPendingWrites? 'locally' : 'on remote', doc.data())
        try {
          if (doc.metadata.hasPendingWrites) return
          const docData = doc.data()
          const { balance: serverBalance, operation } = docData || {}
          if (!docData) {
            const now = new Date().toISOString()
            const newUser = {
              email: user.email,
              displayName: user.displayName,
              balance: zeroBalance,
              operation: null,
              createdOn: now,
              updatedOn: now
            }
            logger?.debug('user doc is empty, creating new user', newUser)
            await setDoc(userDocRef, newUser,{ merge: true })
            setBalance(zeroBalance)
          } else {
            if (operation) {
              const endTime = new Date(operation.endTime)
              const now = new Date()
              if (now > endTime) {
                if (operation.type === 'deposit') {
                  const newBalance = addBalance(serverBalance, operation.duration * (operation.rate || 1), 0)
                  await updateDoc(userDocRef,
                    {
                      balance: newBalance,
                      operation: null,
                      updatedOn: new Date().toISOString()
                    }
                  )
                  setBalance(newBalance)
                }
                if (operation.type === 'withdraw') {
                  const newBalance = subtractBalance(serverBalance, operation.duration, 0)
                  if (newBalance.hours < 0 || newBalance.minutes < 0 || newBalance.seconds < 0) {
                    newBalance.hours = 0
                    newBalance.minutes = 0
                    newBalance.seconds = 0
                  }
                  await updateDoc(userDocRef,
                    {
                      balance: newBalance,
                      operation: null,
                      updatedOn: new Date().toISOString()
                    }
                  )
                  setBalance(newBalance)
                }
              } else {
                if (operation.type === 'deposit' && statusRef.current !== Status.DEPOSITING) {
                  startDeposit({
                    endTime: operation.endTime,
                    duration: operation.duration,
                    balance: serverBalance,
                    rate: operation.rate
                  })
                }
                if (operation.type === 'withdraw' && statusRef.current !== Status.WITHDRAWING) {
                  startWithdraw({
                    endTime: operation.endTime,
                    duration: operation.duration,
                    balance: serverBalance
                  })
                }
              }
            } else {
              if (statusRef.current === Status.DEPOSITING) stopDeposit(true)
              if (statusRef.current === Status.WITHDRAWING) stopWithdraw(true)
              setBalance(serverBalance)
            }
          }
          if (statusRef.current === Status.INITIALIZING) setStatus(Status.READY)
        } catch (err) {
          console.log(err)
          setStatus(Status.ERROR)
        }
      },
      (err) => {
        console.error(err)
        setStatus(Status.ERROR)
      }
    )
    return detachUserDoc
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useBeforeunload((event) => {
    if (status === Status.DEPOSITING || status === Status.WITHDRAWING) {
      event.preventDefault()
    }
  })

  const startDeposit = async (options) => {
    if (!options) clickHowl.play()
    else {
      logger?.debug('resuming deposit with options: ', options)
      duration.onChange({ target: { value: options.duration } })
      depositRate.onChange({ target: { value: options.rate } })
    }
    setStatus(Status.SYNCING)
    const prevBalance = options ? { ...options.balance} : { ...balance }
    const now = new Date()
    const opDuration = options?.duration || duration.value
    const endTime = options ? new Date(options.endTime) : new Date(+now + opDuration * 60 * 1000)
    const rate = options?.rate || depositRate.value
    try {
      if (!options) {
        const options = {
          operation: {
            type: 'deposit',
            duration: opDuration,
            endTime: endTime.toISOString(),
            rate: rate
          },
          updatedOn: new Date().toISOString()
        }
        logger?.debug('starting deposit with options: ', options)
        await updateDoc(userDocRef, options)
      }
      const depositInterval = setInterval(() => {
        if (isPaused.current) {
          logger?.debug('interval:: skipped')
          return
        }
        const remainingTime = getRemainingTime(endTime)
        setRemainingTime(remainingTime)
        const { minutes: remainingMinutes, seconds: remainingSeconds } = remainingTime
        setBalance(() => {
          let { minutes: elapsedMinutes, seconds: elapsedSeconds } = getElapsedTime(endTime, opDuration, rate)
          if (elapsedMinutes >= opDuration) {
            elapsedMinutes = opDuration
            elapsedSeconds = 0
          }
          logger?.debug(
            'interval::',
            'remaining:', remainingMinutes, remainingSeconds,
            'elapsed:', elapsedMinutes, elapsedSeconds
          )
          const newBalance = addBalance(prevBalance, elapsedMinutes, elapsedSeconds)
          return newBalance
        })
      }, 1000)
      const depositTimeout = setTimeout(async () => {
        logger?.debug('deposit timeout')
        silentAudio.stop()
        clearInterval(depositInterval)
        setRemainingTime(zeroRemainingTime)
        showNotification({
          title: 'Jack of Time',
          body: `Your deposit of ${opDuration} minutes is complete!`
        })
        notificationHowl.play()
        try {
          await updateDoc(userDocRef,
            {
              balance: addBalance(prevBalance, opDuration, 0),
              operation: null,
              updatedOn: new Date().toISOString()
            }
          )
          setStatus(Status.READY)
        } catch (err) {
          console.error(err)
          setStatus(Status.ERROR)
        }
      }, (endTime - now) + 3000)
      timers.current = { depositTimeout, depositInterval }
      silentAudio.start()
      if (!options) startOpHowl.play()
      setStatus(Status.DEPOSITING)
    } catch (err) {
      console.error(err)
      setStatus(Status.ERROR)
    }
  }

  const stopDeposit = async (silent) => {
    const windDownDeposit = () => {
      silentAudio.stop()
      clearTimeout(timers.current.depositTimeout)
      clearInterval(timers.current.depositInterval)
      timers.current = null
      setRemainingTime(zeroRemainingTime)
      setStatus(Status.READY)
    }
    if (!silent) {
      logger?.debug('stopping deposit')
      clickHowl.play()
      setStatus(Status.SYNCING)
      isPaused.current = true
      try {
        await updateDoc(userDocRef,
          {
            balance: balance,
            operation: null,
            updatedOn: new Date().toISOString()
          }
        )
        windDownDeposit()
        isPaused.current = false
      } catch (err) {
        console.error(err)
        isPaused.current = false
        setStatus(Status.ERROR)
      }
    } else {
      logger?.debug('silently stopping deposit')
      windDownDeposit()
    }
  }

  const startWithdraw = async (options) => {
    if (!options) clickHowl.play()
    else {
      logger?.debug('resuming withdraw with options: ', options)
      duration.onChange({ target: { value: options.duration } })
    }
    setStatus(Status.SYNCING)
    const prevBalance = options ? { ...options.balance} : { ...balance }
    const now = new Date()
    const opDuration = options?.duration || duration.value
    const endTime = options ? new Date(options.endTime) : new Date(+now + opDuration * 60 * 1000)
    try {
      if (!options) {
        const options = {
          operation: {
            type: 'withdraw',
            duration: opDuration,
            endTime: endTime.toISOString()
          },
          updatedOn: new Date().toISOString()
        }
        logger?.debug('starting withdraw with options: ', options)
        await updateDoc(userDocRef, options)
      }
      const withdrawInterval = setInterval(() => {
        if (isPaused.current) {
          logger?.debug('interval:: skipped')
          return
        }
        const remainingTime = getRemainingTime(endTime)
        setRemainingTime(remainingTime)
        const { minutes: remainingMinutes, seconds: remainingSeconds } = remainingTime
        setBalance(() => {
          let { minutes: elapsedMinutes, seconds: elapsedSeconds } = getElapsedTime(endTime, opDuration)
          if (elapsedMinutes >= opDuration) {
            elapsedMinutes = opDuration
            elapsedSeconds = 0
          }
          logger?.debug(
            'interval::',
            'remaining:', remainingMinutes, remainingSeconds,
            'elapsed:', elapsedMinutes, elapsedSeconds
          )
          const newBalance = subtractBalance(prevBalance, elapsedMinutes, elapsedSeconds)
          return newBalance
        })
      }, 1000)
      const withdrawTimeout = setTimeout(async () => {
        logger?.debug('withdraw timeout')
        silentAudio.stop()
        clearInterval(withdrawInterval)
        setRemainingTime(zeroRemainingTime)
        showNotification({
          title: 'Jack of Time',
          body: `Your withdrawal of ${opDuration} minutes is complete!`
        })
        notificationHowl.play()
        try {
          await updateDoc(userDocRef,
            {
              balance: subtractBalance(prevBalance, opDuration, 0),
              operation: null,
              updatedOn: new Date().toISOString()
            }
          )
          setStatus(Status.READY)
        } catch (err) {
          console.error(err)
          setStatus(Status.ERROR)
        }
      }, (endTime - now) + 3000)
      timers.current = { withdrawTimeout, withdrawInterval }
      silentAudio.start()
      if (!options) startOpHowl.play()
      setStatus(Status.WITHDRAWING)
    } catch (err) {
      console.error(err)
      setStatus(Status.ERROR)
    }
  }

  const stopWithdraw = async (silent) => {
    const windDownWithdraw = () => {
      silentAudio.stop()
      clearTimeout(timers.current.withdrawTimeout)
      clearInterval(timers.current.withdrawInterval)
      timers.current = null
      setRemainingTime(zeroRemainingTime)
      setStatus(Status.READY)
    }
    if (!silent) {
      logger?.debug('stopping withdraw')
      clickHowl.play()
      setStatus(Status.SYNCING)
      isPaused.current = true
      try {
        await updateDoc(userDocRef,
          {
            balance: balance,
            operation: null,
            updatedOn: new Date().toISOString()
          }
        )
        windDownWithdraw()
        isPaused.current = false
      } catch (err) {
        console.error(err)
        isPaused.current = false
        setStatus(Status.ERROR)
      }
    } else {
      logger?.debug('silently stopping withdraw')
      windDownWithdraw()
    }
  }

  const resetBalance = async () => {
    await Storage.setItem('balance', zeroBalance)
    setBalance(zeroBalance)
  }

  return (
    <>
      <AlertDialog
        open={confirmReset}
        onClose={() => setConfirmReset(false)}
        onConfirm={resetBalance}
        title="Reset Balance"
        content='This will reset your Account Balance to zero. Sure you want to do that?'
        closeBtnTitle="Cancel"
        confirmBtnTitle="Reset"
      />
      <Prompt
       when={status === Status.DEPOSITING || status === Status.WITHDRAWING}
       message="Leaving? Changes may not be saved." />
      <Layout>
        <Container maxWidth='md' sx={{ py: 4 }}>          
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Widget sx={{
                height: isSmallScreen ? 'auto' : theme.spacing(16),
                textAlign: 'right'
              }}>
                <Box>
                  <Typography component='span' variant='overline'>Account Balance</Typography>
                  {/* <Tooltip title='Reset' placement='top' arrow>
                    <Box component='span'>
                      <IconButton
                        size='small'
                        onClick={() => setConfirmReset(true)}
                        color='secondary'
                        disabled={status !== Status.READY}
                      >
                        <ResetIcon fontSize='small'/>
                      </IconButton>
                    </Box>
                  </Tooltip> */}
                </Box>
                <Typography
                  variant='h1'
                  color={status === Status.INITIALIZING ? 'gray' : 'inherit'}
                >
                  {`${pad0(balance.hours)}:${pad0(balance.minutes)}:${pad0(balance.seconds)}`}
                </Typography>
              </Widget>
            </Grid>
            <Grid item xs={12} md={4}>
              <Widget sx={{
                height: isSmallScreen ? 'auto' : theme.spacing(16)
              }}>
                <Grid
                  container
                  spacing={isSmallScreen ? 2 : 0 }
                  justifyContent='center'
                  alignItems='center'
                  sx={{ height: '100%' }}
                >
                  <Grid item sm={6} md={12}>
                    <Button
                      variant='contained'
                      color={status === Status.DEPOSITING ? 'error' : 'primary'}
                      size='large'
                      fullWidth
                      sx={{
                        height: isSmallScreen ? 'inherit' : theme.spacing(6)
                      }}
                      disabled={
                        !(status === Status.READY || status === Status.DEPOSITING) ||
                        (status === Status.DEPOSITING && remainingTime.minutes === 0 && remainingTime.seconds <= 10)
                      }
                      onClick={() => status === Status.DEPOSITING ? stopDeposit() : startDeposit() }
                    >
                      {status === Status.DEPOSITING ? 'Stop' : 'Start'} Deposit
                    </Button>
                  </Grid>
                  <Grid item sm={6} md={12}>
                    <Button
                      variant='outlined'
                      color={status === Status.WITHDRAWING ? 'error' : 'primary'}
                      size='large'
                      fullWidth
                      sx={{
                        height: isSmallScreen ? 'inherit' : theme.spacing(6)
                      }}
                      disabled={
                        !(status === Status.READY || status === Status.WITHDRAWING) ||
                        (status === Status.WITHDRAWING && remainingTime.minutes === 0 && remainingTime.seconds <= 10) ||
                        (status !== Status.WITHDRAWING && (balance.hours === 0 && (duration.value > balance.minutes)))
                      }
                      onClick={() => status === Status.WITHDRAWING ? stopWithdraw() : startWithdraw() }
                    >
                      {status === Status.WITHDRAWING ? 'Stop' : 'Start'} Withdraw
                    </Button>
                  </Grid>
                </Grid>
              </Widget>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Widget sx={{ py: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Typography variant='h6' component='span'>Status: </Typography>
                    <Typography variant='body1' component='span' color='secondary'>
                      {status === Status.INITIALIZING && 'Initializing...'}
                      {status === Status.READY && 'Ready'}
                      {status === Status.DEPOSITING && 'Deposit in progress'}
                      {status === Status.WITHDRAWING && 'Withdraw in progress'}
                      {status === Status.SYNCING && 'Communicating with server...'}
                      {status === Status.ERROR && 'Something went wrong. Please try reloading.'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign='right'>
                    { (status === Status.INITIALIZING ||
                      status === Status.SYNCING) &&
                      <CircularProgress size={24} color='secondary' sx={{ p: 0.25 }} />
                    }
                  </Grid>
                </Grid>
              </Widget>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={8} md={9}>
              <Widget>
                <Box mb={2}>
                  <Typography variant='h6' component='span'>
                    {status === Status.DEPOSITING || status === status.WITHDRAWING ?
                      'Remaining: ' :
                      'Duration: '
                    }
                  </Typography>
                  <Typography variant='body1' component='span'>
                    {remainingTime !== zeroRemainingTime ?
                      `${pad0(remainingTime.minutes)}m ${pad0(remainingTime.seconds)}s` :
                      `${pad0(duration.value)}m 00s`
                    }
                  </Typography>
                </Box>
                <Slider
                  step={5}
                  marks={[
                    { value: 5, label: '5' },
                    { value: 25, label: '25' },
                    { value: 60, label: '60' }
                  ]}
                  min={5}
                  max={60}
                  {...duration}
                  disabled={status !== Status.READY}
                />
              </Widget>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Widget sx={{ py: 2 }}>
                <Box mb={2}>
                  <Typography variant='h6' sx={{ mb: 2 }}>
                    Deposit Rate
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      {...depositRate}
                      disabled={status !== Status.READY}
                    >
                      <MenuItem value={0.5}>Half (0.5x)</MenuItem>
                      <MenuItem value={1}>Standard (1x)</MenuItem>
                      <MenuItem value={2}>Double (2x)</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Widget>
            </Grid>
          </Grid>
          <InfoSection />
          <Footer openLinksInNewTab={true} />
        </Container>
      </Layout>
    </>
  )
}

export default HomeView
