import * as React from 'react'
import Typography from '@mui/material/Typography'
import HeartIcon from '@mui/icons-material/Favorite'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'

import Widget from './Widget'

export default function Footer({ openLinksInNewTab = false }) {
  return (
    <Widget sx={{ p: 1.5, mt: 4, textAlign: 'center' }}>
      <Typography variant='body2'>
        Made with
        <HeartIcon color='error' sx={{ fontSize: 16, verticalAlign: 'middle', px: 0.5 }} />
        in India
      </Typography>
      <Typography variant='body2'>
        Copyright © {new Date().getFullYear()} <Link underline='hover' href='https://morrowcraft.com' target='_blank'>
          Morrowcraft
        </Link>.
      </Typography>
      <Divider sx={{ mt: { xs: 2, sm: 1 }, mb: { xs: 2, sm: 1 } }}/>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={12} sm='auto' sx={{ p: 0 }}>
          <Link
            variant='body2'
            target={openLinksInNewTab ? '_blank' : '_self'}
            underline='hover'
            href='/'
          >
              Home
          </Link>
        </Grid>
        <Grid item xs={12} sm='auto' sx={{ p: 0 }}>
          <Link
            variant='body2'
            target={openLinksInNewTab ? '_blank' : '_self'}
            underline='hover'
            href='/support'
          >
              Support
          </Link>
        </Grid>
        <Grid item xs={12} sm='auto' sx={{ p: 0 }}>
          <Link
            variant='body2'
            target={openLinksInNewTab ? '_blank' : '_self'}
            underline='hover'
            href='/pricing'
          >
              Pricing
          </Link>
        </Grid>
        <Grid item xs={12} sm='auto' sx={{ p: 0 }}>
          <Link
            variant='body2'
            target='_blank'
            underline='hover'
            href='https://morrowcraft.com/terms'
          >
              Terms of Use
          </Link>
        </Grid>
        <Grid item xs={12} sm='auto' sx={{ p: 0 }}>
          <Link
            variant='body2'
            target='_blank'
            underline='hover'
            href='https://morrowcraft.com/privacy'
          >
              Privacy Policy
          </Link>
        </Grid>
      </Grid>
    </Widget>
  )
}
