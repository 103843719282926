import { grey, pink, teal } from '@mui/material/colors'
import { createTheme, responsiveFontSizes } from "@mui/material/styles"

const darkTheme = responsiveFontSizes(createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: grey[400],
      paper: grey[900],
      appBar: '#111111'
    },
    primary: {
      main: teal[200],
      contrastText: grey['900']
    },
    secondary: {
      main: pink[200],
      contrastText: grey['900']
    },
    text: {
      primary: grey['A200']
    }
  },
  typography: {
    h1: {
      fontFamily: '"Lora", serif'
    },
    h2: {
      fontFamily: '"Lora", serif'
    },
    h3: {
      fontFamily: '"Lora", serif'
    },
    h4: {
      fontFamily: '"Lora", serif'
    },
    h5: {
      fontFamily: '"Lora", serif'
    },
    h6: {
      fontFamily: '"Lora", serif'
    },
    subtitle1: {
      fontSize: '1.25rem',
    }
  }
}))

export default darkTheme
