import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider, signOut } from 'firebase/auth'
import { getAnalytics, logEvent as logE } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { createContext, useContext } from 'react'

// config
import config from '../config'

const { environments } = config
const appEnv = process.env.REACT_APP_ENV

const firebaseConfig = {
  [environments.PRODUCTION]: {
    apiKey: "AIzaSyBmeydwpGVwavjv9oCaTqlZyNZVri5g7Bs",
    authDomain: "jack-of-time.firebaseapp.com",
    projectId: "jack-of-time",
    storageBucket: "jack-of-time.appspot.com",
    messagingSenderId: "691538045471",
    appId: "1:691538045471:web:d2d497ab2513026681032a",
    measurementId: "G-KR3G32GFTM"
  },
  [environments.DEVELOPMENT]: {
    apiKey: "AIzaSyBTj-vOvzL1VhStKLo1g_9xkgJML5GtmnQ",
    authDomain: "jack-of-time-staging.firebaseapp.com",
    projectId: "jack-of-time-staging",
    storageBucket: "jack-of-time-staging.appspot.com",
    messagingSenderId: "44609307321",
    appId: "1:44609307321:web:5677c4393827b8c04a5168",
    measurementId: "G-HBXLDWWZLB"
  }
}

const firebaseApp = initializeApp(firebaseConfig[appEnv])
const analytics = getAnalytics(firebaseApp)
const firebaseAuth = getAuth(firebaseApp)
const authProviders = {
  google: new GoogleAuthProvider(),
  apple: new OAuthProvider('apple.com')
}
const firestore = getFirestore(firebaseApp)

const firebaseLogin = async (authProvider = 'google') => {
  try {
    const result = await signInWithPopup(firebaseAuth, authProviders[authProvider])
    return result
  } catch (error) {
    console.log(error)
  }
}

const firebaseLogout = async () => {
  try {
    return await signOut(firebaseAuth)
  } catch (error) {
    console.log(error)
  }
}

const logEvent = (...params) => logE(analytics, ...params) 

const FirebaseContext = createContext()

const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider
      value={{
        firebaseApp,
        firebaseAuth,
        firebaseLogin,
        firebaseLogout,
        logEvent,
        firestore
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

const useFirebase = () => {
  const firebase = useContext(FirebaseContext)
  if (!firebase.firebaseApp) {
    throw new Error('useFirebase must be used within a FirebaseProvider')
  }
  return firebase
}

export { FirebaseProvider, useFirebase }

