import Box from '@mui/material/Box'
import MenuBar from './MenuBar'
import BackgroundImage from '../assets/images/bg.jpg'

const Layout = ({children}) => {
  return <Box sx={{
    minHeight: '100vh',
    bgcolor: 'background.default',
    backgroundImage: `url(${BackgroundImage}), linear-gradient(110deg, rgb(20, 21, 23) 0%, rgb(41, 39, 40) 87%, rgb(65, 41, 39) 98%)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  }}>
    <MenuBar />
    {children}
  </Box>
}

export default Layout