import config from './config'

const { environments } = config
const appEnv = process.env.REACT_APP_ENV
const isDevelopment = appEnv === environments.DEVELOPMENT

const logger = isDevelopment ? console : null

function SilentAudio() {
    this.context = null

    this.start = () => {
      logger?.debug('started silent audio to keep timers alive in background during operation')
      this.context = new AudioContext()
      const source = this.context.createConstantSource()
      const gainNode = this.context.createGain()
      gainNode.gain.value = 0.001
      
      source.connect(gainNode)
      gainNode.connect(this.context.destination)
      source.start()
    }

    this.stop = () => {
      logger?.debug('stopped silent audio')
      if (this.context) this.context.close()
    }
}

export {
  logger,
  SilentAudio
}