import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

const Widget = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: 'rgba(0,0,0,0.75)',
  backdropFilter: `blur(${theme.spacing(1)})`
}))

export default Widget