import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import LinearProgress from '@mui/material/LinearProgress'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import GoogleIcon from '@mui/icons-material/Google'
import AppleIcon from '@mui/icons-material/Apple'
import { useAuth } from '../../contexts/authContext.js'
import { useFirebase } from '../../contexts/firebaseContext.js'
import { useHistory } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'

// components
import Layout from '../../components/Layout'
import Widget from '../../components/Widget'

function LoginView() {
  const auth = useAuth()
  const { firebaseAuth, firebaseLogin, firebaseLogout } = useFirebase()
  // eslint-disable-next-line no-unused-vars
  const [user, loading, error] = useAuthState(firebaseAuth)
  const history = useHistory()

  return (
    <>
      <Layout>
        <Container maxWidth='xs'>          
          {loading && <Grid container justifyContent='center' sx={{ mt: 2, height: '80vh' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' , alignItems: 'center', height: '100%' }}>
              <Widget sx={{ flex: 1 }}>
                <Typography variant='h5' sx={{ mb: 3, textAlign: 'center' }}>
                  Authenticating...
                </Typography>
                <LinearProgress sx={{ mb: 3 }} />
              </Widget>
            </Grid>
          </Grid>}
          {user && <Grid container justifyContent='center' sx={{ mt: 2, height: '80vh' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' , alignItems: 'center', height: '100%' }}>
              <Widget sx={{ flex: 1, textAlign: 'center' }}>
                <Typography variant='subtitle1' sx={{ mb: 3, textAlign: 'center' }}>
                  Welcome back!
                </Typography>
                {user.photoURL &&
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 2
                  }}>
                    <Avatar
                      src={user.photoURL}
                      sx={{ width: 100, height: 100, border: '4px solid #ccc' }}
                    />
                </Box>}
                {user.displayName && <Typography variant='h4' sx={{ mb: 1 }}>
                  {user.displayName}
                </Typography>}
                <Typography variant='body1' sx={{ mb: 3 }}>
                  {user.email}
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  sx={{ mr: 2 }}
                  disabled={loading}
                  onClick={() => {
                    auth.login(user)
                    history.push('/app')
                  }}
                >
                  I'm back!
                </Button>
                <Button
                  variant='outlined'
                  color='error'
                  disabled={loading}
                  onClick={async () => {
                    try {
                      await firebaseLogout()
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  Not me!
                </Button>
              </Widget>
            </Grid>
          </Grid>}
          {!user && !loading && <Grid container spacing={2} justifyContent='center' sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Widget>
                <Typography variant='h4' sx={{ mb: 3, textAlign: 'center' }}>Welcome!</Typography>
                <Typography variant='h6' sx={{ mb: 2 }}>Disclaimer</Typography>
                <Typography variant='body1' sx={{ mb: 2 }}>
                  Jack of Time is in currently in beta and may be rough around the edges.                  
                  If you find any bugs or have any suggestions for improvement, please  
                  <Link href='/support' target='_blank' underline='hover'> let us know</Link>.
                </Typography>
                <Typography variant='body1' sx={{ mb: 2 }}>
                  We very much appreciate you trying it out and look forward to your valuable feedback.
                </Typography>
                <Typography variant='body1' sx={{ mb: 3 }}>
                  Hope you enjoy it! 🙂
                </Typography>                
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled={loading}
                    fullWidth
                    onClick={async () => {
                      try {
                        const loginResult = await firebaseLogin('google')
                        if (loginResult.user) {
                        auth.login(loginResult.user)
                        history.push('/app')
                      }
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                  >
                    <GoogleIcon fontSize='small' sx={{ mr: 1 }} /> Login with Google
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled={true}
                    fullWidth
                    onClick={async () => {
                      try {
                        const loginResult = await firebaseLogin('apple')
                        if (loginResult.user) {
                        auth.login(loginResult.user)
                        history.push('/app')
                      }
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                  >
                    <AppleIcon fontSize='small' sx={{ mr: 1 }} /> Login with Apple
                  </Button>
                </Box>
                <Typography variant='caption'>
                  Please note that by creating an account with us, you agree to our <Link
                    href='https://morrowcraft.com/terms'
                    target='_blank'
                    rel='noopener noreferrer'
                    underline='hover'
                  >Terms of Use</Link> and <Link
                    href='https://morrowcraft.com/privacy'
                    target='_blank'
                    rel='noopener noreferrer'
                    underline='hover'
                  >Privacy Policy</Link>.
                </Typography>
              </Widget>
            </Grid>
          </Grid>}
        </Container>
      </Layout>
    </>
  )
}

export default LoginView
