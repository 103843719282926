import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const AlertDialog = (props) => {
  const {
    open = false,
    onClose = () => {},
    title = 'Title',
    content = 'Message',
    onConfirm = () => {},
    closeBtnTitle = 'Close',
    confirmBtnTitle = 'Confirm'
  } = props

  const handleClose = () => {
    onClose()
  }
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{closeBtnTitle}</Button>
          <Button
            color='error'
            onClick={handleConfirm}
            autoFocus
          >
            {confirmBtnTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AlertDialog
