import { createContext, useContext, useState } from 'react'

const AuthContext = createContext({isAuthenticated: false, user: {}, signIn: () => {}})

const AuthProvider = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({})

  const login = (user) => {
    setIsAuthenticated(true)
    setUser(user)
    console.debug('login', user)
  }

  const logout = () => {
    setIsAuthenticated(false)
    setUser({})
    console.debug('logout')
  }

  return (
    <AuthContext.Provider value={{isAuthenticated, user, login, logout}}>
      {children}
    </AuthContext.Provider>
  )
}
// useAuth hook with error handling
const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }