import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link as RouterLink } from 'react-router-dom'

import Widget from '../../components/Widget'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'

export default function SupportView() {
  return (
    <Layout>
      <Container maxWidth='lg' sx={{ pt: 8, pb: 4 }}>
        <Widget>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} underline='hover' to='/'>
              Home
            </Link>
            <Typography>Support</Typography>
          </Breadcrumbs>
        </Widget>
        <Widget sx={{ mt: 2, p: { xs: 4, sm: 8 } }}>
          <Typography variant="h4" sx={{ textAlign: 'center', mb: 6 }}>Support</Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant='body1' component='span' sx={{ mr: 1 }}>
              For queries, feedback, bug reports or feature requests, you can email our support team:
            </Typography>
            <Typography variant='body1' color='secondary' component='span'>
              support [at] morrowcraft [dot] com
            </Typography>
          </Box>
          <Button
              variant='contained'
              underline='hover'
              onClick={() => {
                const appName = 'jackoftime'
                const domain = 'morrowcraft.com'
                const team = 'support'
                const email = `${team}+${appName}@${domain}`
                const subject = '[Jack of Time] Support Request'
                window.open(`mailto:${email}?subject=${subject}`)
              }}
            >
              Contact Support
            </Button>
            <Box sx={{ mt: 8 }}>
              <Typography variant='h6' sx={{ mb: 4 }}>Frequently Asked Questions (FAQs)</Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
                  1. How much does it cost to use Jack of Time?
                </Typography>
                <Typography variant='body1'>
                  Currently, Jack of Time is free to use until Dec 31, 2022.
                  Even after that, there will always be a Forever Free plan available for casual and trial users.
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
                  2. Can I login with both Apple and Google accounts?
                </Typography>
                <Typography variant='body1'>
                  Only if they use the same email address. So, generally no; you'll end up creating two entirely separate accounts.
                  There are plans to add support for linking accounts in the future though. Until then, you'll have to use one or the other.
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
                  3. What is the maximum balance I can have?
                </Typography>
                <Typography variant='body1'>
                  1000 hours. But, isn't the whole point to balance your time usage. We hope it never comes to that.
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
                  4. I forgot to start/stop the deposit/withdraw. What do I do?
                </Typography>
                <Typography variant='body1'>
                  Don't worry about it. The goal is to be more aware of your time usage, not micromanage it.
                  Just remember to start the operation next time. And use the stop button sparingly.
                  Try to commit to whatever you start an operation for, till the duration runs out.
                  Take a break when that happens instead of immediately starting a new operation.
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
                  5. Is there an iOS/Android app?
                </Typography>
                <Typography variant='body1'>
                  Both iOS and Android apps are coming soon.
                </Typography>
              </Box>
            </Box>
        </Widget>
        <Footer />
      </Container>
    </Layout>
)}
