import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

export default function MenuBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{
          backgroundColor: 'background.appBar'
        }}>
          <Typography variant="h5" component="span" sx={{ 
            flexGrow: 1,
            textAlign: 'center',
            fontStyle: 'italic',
          }}>
            Jack of Time <Typography variant='caption' component='sup'>beta</Typography>
          </Typography>
          
        </Toolbar>
      </AppBar>
    </Box>
  );
}
