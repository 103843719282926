import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Widget from '../../../components/Widget'
import List from '@mui/material/List'
import ListItem from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'

export default function InfoSection() {
  return (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Widget>
          <Typography variant='h5' mt={1} mb={1}>
            How to Use
          </Typography>
          <Typography variant='body1' mt={1}>
            Deposit time when doing productive work. Withdraw stored time to spend it on leisurely activities.
            The definition of productive and leisurely activities is entirely up to you. The idea is to become more aware
            of your time usage, be more intentional about it and gradually strike a healthy balance between the two.
          </Typography>
          <Typography variant='body1' mt={1}>
            For example, let's consider Sean, one of our awesome engineers who worked on this app. Like many of us, he struggled to strike
            a balance between spending time enjoying his hobbies (exploring awesome video games by indie developers) and crafting amazing
            projects such as this. Now, he uses Jack of Time to Deposit time when working on a project and then Withdraw the stored time to play
            indie games without feeling guilty or having to keep track of his time budget. Pretty neat, huh? 🙂
          </Typography>
          <Box mt={3} mb={1}>
            <Typography variant='h5'>Latest Changes</Typography>
            <Typography variant='caption'>23rd Jun, 2022</Typography>
          </Box>          
          <List sx={{ pl: 2 }}>
            <ListItem disablePadding>
              <ListItemText
                primary='• Laid the groundwork for Apple SignIn (Coming Soon)'
                primaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary='• Server sync is now instantaneous and more reliable.'
                primaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary='• Fixed a bug where the deposit rate was being ignored in certain cases.'
                primaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItem>
          </List>
        </Widget>
      </Grid>
    </Grid>
  )
}
